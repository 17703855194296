/* Import slick-carousel CSS files */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.snip1584 {
  font-family: 'Monospace', sans-serif;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 10px;
  max-width: 100%;
  width: 315px;
  color: #ffffff;
  font-size: 16px;
  text-align: left;
  transition: transform 0.3s ease;
}

.snip1584 img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  vertical-align: top;
  backface-visibility: hidden;
}

.snip1584 .info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(51, 51, 51, 0.8);
  padding: 10px;
  text-align: center;
  max-height: 70px; /* Initially limit the height to show only the title */
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.snip1584 .info h3 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.snip1584 .info p {
  margin: 10px 0 0;
  font-size: 14px; /* Adjust the font size for the biography here */
  opacity: 0;
  transition: opacity 0.3s ease;
  max-height: 100%;
}

/* Ensure the styles are applied with higher specificity */
.snip1584 .info p.biography {
  font-size: 14px !important; /* Adjust the font size for the biography here */
  opacity: 0;
  transition: opacity 0.3s ease;
  max-height: 200px; /* Limit the height for the scroll */
  overflow-y: auto; /* Enable vertical scroll */
  text-align: justify;
  padding: 10px 10px;
}

/* Show full biography on hover */
.snip1584:hover .info {
  max-height: 300px; /* Adjust to ensure the full biography can be shown */
}

.snip1584:hover .info p {
  opacity: 1; /* Make biography visible */
}

/* Custom styles for Slick carousel arrows */
.slick-prev:before,
.slick-next:before {
  color: #333;
  font-size: 24px;
  margin-left: 10px;
}

.slick-prev {
  left: -25px;
}

.slick-next {
  right: 0px;
}

/* Footer styles */
#footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f1f1f1;
  color: #333;
}

/* Media Queries
@media (max-width: 768px) {
  .snip1584 {
    width: 100%;
  }
} */

/* @media (max-width: 500px) {
  .snip1584 img {
    height: 200px;
  }
} */

.info span {
  color: var(--golden_color);
}

