#footer-container {
  padding-top: 1%;
    color: white;
    width: 100%;
    display: block;
    height: 150px;
    /* background-color: red; */
    background-color: var(--greenish_color);
    padding-bottom: 0;
  }
  
  .footer-col ul {
    padding-left: 15px;
  }
  .footer-col a {
    color: white;
    text-decoration: none;
  }
  
  #footer-links-logo {
    display: block;
    width: 100%;
    height: 100px;
    /* background-color: pink; */
  }

  #footer-links {
    /* flex-wrap: wrap; Allow items to wrap to the next line if necessary */
    justify-content: space-between;
    width: 80%;
    display: flex;
    /* height: 200px; */
    /* background-color: yellow; */
    float: left;
    padding: 0.5rem 3rem;
  }
  
  #footer-links h3 {
    padding-bottom: 1rem;
    /* color: var(--golden_color); */
  }
  
  #footer-links a:hover {
    color: var(--golden_color);
		transition: 200ms ease-in;
		font-size: 1.2rem;
		font-weight: bold;
  }

  #footer-logo {
    display: flex;
    float: right;
    width: 20%;
    height: 100px;
    display: block;
    text-align: center;
    /* background-color: green; */
  }

  #footer-logo img {
        width: 40%;
  } 
  
  #copyright {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    color: var(--golden_color);
    /* height: 100px; */
    /* background-color: blue; */
  }
  .footer-col {
    /* width: 5%; */
    /* border: 2px solid #969696; */
    /* background-color: cyan; */
    /* border-color: purple; */
    /* border-radius: 1px; */
    margin-left: 20px;
    /* height: 200px; */
  }
  
  /* Media query for mobile view */
  @media (max-width: 768px) {
    #footer-links {
      width: 75%;
    }
  
    #footer-logo {
      width: 25%;
    }
    #footer-links {
      display: block;
    }
    .footer-col {
      height: fit-content;
      margin-bottom: 20px;
    }
    #footer-logo img {
        width: 100%;
  } 
  #footer-links h3 {
    padding-bottom: 0.1rem;
    /* color: var(--golden_color); */
  }
  #footer-container {
    height: 275px;
  }
  #footer-links-logo {
    height: 250px;
  }
  #footer-links {
    height: 200px;
  }
  }
  
  